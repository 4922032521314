import $ from 'jquery';



if( $ ( 'select[data-widget="select2"]' ).length > 0 ) {
  let multipleSelectWidget = $ ( document ).find ( 'select.multiple_select' );
  $.each ( multipleSelectWidget, function ( val ) {
    $ ( this ).attr ( 'id', $ ( this ).data ( 'id' ) );
  } );
  multipleSelectWidget.select2 ( {
    theme: "classic",
    // theme       : 'flat',
    width       : '100%',
    allowClear  : true,
    closeOnSelect: false,
    'language'  : {
      'noResults' : function () {
        return 'Brak wyników';
      }
    },
  });

  /**
   *
   *
   */
  let singleSelectWidget = $ ( document ).find ( 'select.single_select' );
  $.each ( singleSelectWidget, function ( val ) {
    $ ( this ).attr ( 'id', $ ( this ).data ( 'id' ) );
  } );
  singleSelectWidget.select2 ( {
    theme: "classic",
    // theme       : 'flat',
    width       : '100%',
    allowClear  : true,
    closeOnSelect: true,
    'language'  : {
      'noResults' : function () {
        return 'Brak wyników';
      }
    },
  });



}




import $ from 'jquery';


$ ( document ).ready ( function () {
  function FilterWatcher( select, parent, data, compare ) {
    this.$select = $ ( select );
    this.$parent = $ ( parent );
    this.$options = this.$select.children ( 'option:not(:first-child)' );
    this.currentSelect = this.$select.children ( 'option:selected' ).val ();
    this.currentParent = this.$parent.children ( 'option:selected' ).val ();
    this.compare = compare;

    this.data = data; //carStorage
    this.init ();
    this.watch ();
  }

  FilterWatcher.prototype = {
    init() {
      $ (
        '<style>.select2-results .select2-disabled,  .select2-results__option[aria-disabled=true] { display: none;}</style>',
      ).appendTo ( 'head' );
      this.filter ();
      this.watch ();
    },

    clear() {
      // console.log(this.$select);
      this.$select.val ( null ).trigger ( 'change' );
    },

    watch() {
      this.$parent.change ( () => {
        const selected = this.$parent.children ( 'option:selected' ).val ();
        if( this.currentSelect !== 0 && this.currentParent !== selected ) {
          this.clear ();
        }
        this.currentParent = selected;
        this.filter ();
      } );
    },

    filter() {
      this.$options.each ( ( i, e ) => {
        if( e.value !== '' ) {
          this.$options[ i ].removeAttribute ( 'disabled' );
          //finding proper item
          const item = this.data.find ( d => d.id == e.value );
          // console.log(this.compare)
          // console.log(this.currentParent)
          // console.log(item[this.compare])

          try {
            if( item[ this.compare ] != this.currentParent ) {
              this.$options[ i ].setAttribute ( 'disabled', 'true' );
            }
          } catch ( e ) {

          }


        }
      } );
    },
    constructor : FilterWatcher,
  };


  if( typeof carStorage !== 'undefined' ) {
    let select = new FilterWatcher (
      '#car_model',
      '#car_brand',
      carStorage.models,
      'brand',
    );
    let select2 = new FilterWatcher (
      '#car_version',
      '#car_model',
      carStorage.versions,
      'model',
    );
  }

} );

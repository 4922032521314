import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import './vendor/lightbox.min.js';
import './vendor/select2';
import './myScripts/select';
import './myScripts/car_enquiry';
import './myScripts/select_filter';

AOS.init({
  once: true,
});

tippy('.attribute', {
  content(reference) {
    return reference.querySelector('.tooltip').innerHTML;
  },
  allowHTML: true,
  // trigger: 'click',
});

tippy('.more-details-tooltip-initiator',{
  content(reference) {
    return reference.querySelector('.more-details-tooltip').innerHTML;
  },
  allowHTML: true,
})

if ( $('.car-brand--slider') ){
  const carBrandsSlider = new Flickity('.car-brand--slider',{
    autoPlay: 1500,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    groupCells: 2,
    cellAlign: 'left'
  })
}

if ( $('.car-gallery') ){
  const carGallery = new Flickity('.car-gallery',{
    autoPlay: false,
    pageDots: false,
    prevNextButtons: true,
  })

  const carGalleryThumbnails = new Flickity('.car-thumbnails',{
    asNavFor: '.car-gallery',
    pageDots: false,
    prevNextButtons: false,

  })
}


$(document).ready(function (){

  $(".button-more").click(function (event) {
    event.preventDefault();
    $(".advanced-filters-wrapper").toggleClass('is_open');
  });

  $('#filter_closeButton').click(function (event){
    event.preventDefault();
    $(".advanced-filters-wrapper").removeClass('is_open');
  })

  $(".expand-model-informations").click(function (event) {
    event.preventDefault();
    $(".expand-model-informations-wrapper").toggleClass('is_open');
  });

  $(".make-enquiry-wrapper button").click(function (event) {
    event.preventDefault();
    $('body').toggleClass('form_bar_is_open');
    $(".car_enquiry_form").toggleClass('is_open');

  });

  $(".car_enquiry_form .close-form").click(function (event) {
    event.preventDefault();
    $('body').toggleClass('form_bar_is_open');
    $(".car_enquiry_form").toggleClass('is_open');
  });

  $('.consultationStart').click(function (event){
    event.preventDefault();

    $('.consultation-box').addClass('is-visible')
    $('.consultation-box-overlay').addClass('is-visible')
  })

  $('.consultation-box-close').click(function (event){
    $('.consultation-box').removeClass('is-visible')
    $('.consultation-box-overlay').removeClass('is-visible')
  })
})

const faqItems = Array.from(document.querySelectorAll('.faq-item'));
faqItems.forEach(item=>{
  item.onclick = function (event){
    item.classList.toggle('active')
  }
})

$(document).on('click','.hamburger-menu',function (e) {
  e.preventDefault();

  $('.hamburger-menu').toggleClass('is-open');
  $('.mobile-side-menu').toggleClass('is-open');
  $('.logo-wrapper').toggleClass('is-open');
  $('.mobile-side-menu--overlay').toggleClass('is-open');
});



import $ from 'jquery';

if( $ ( '#carEnquiry' ).length > 0 ) {
  const enquiryForm = $ ( '#carEnquiry' );
  $ ( document ).on ( 'click', '#carEnquiry button[type="submit"]', function ( e ) {
    e.preventDefault ();
    sendRequest('car_enquiry', getFormFields ( enquiryForm ))
  } );
}


if( $('#contactForm').length > 0 ){
  const contactForm = $('#contactForm');

  $ ( document ).on ( 'click', '#contactForm button[type="submit"]', function ( e ) {
    e.preventDefault ();


    if( isFormValid(contactForm) ){
      sendRequest('contact_enquiry', getFormFields ( contactForm ))
    }

  } );
}

function isFormValid(form) {
  let flag = true;
  $(document).find('span.input-validation--error').remove();


  const inputs = form.find('input');
  if( inputs.length > 0 ){
    $.each(inputs, function () {
      const _this = $(this);
      _this.removeClass('input-validation--error');
      const wrapper =  _this.parent();

      if( _this[0].required && _this[0].value === '' ){
        wrapper.append('<span class="input-validation--error">Wypełnij to pole</span>');
        _this.addClass('input-validation--error');

        flag = false;
      }
    })
  }


  return flag;
}

function getFormFields( el ) {
  let fields = {};
  $.each ( el.find ( 'input, select, textarea' ), function ( value, index ) {

    const inputType = $ ( this ).attr ( 'type' ),
          tagName = $ ( this )[ 0 ].nodeName,
          inputName = $ ( this )[0].getAttribute('name');

    if( tagName.toLowerCase () === 'input' ) {
      if( inputType === 'radio' || inputType === 'checkbox' ) {
        fields[ $ ( this ).data ( 'name' ) ] = $ ( this )[ 0 ].checked ? 1 : 0;
      }
      else {
        fields[ $ ( this ).data ( 'name' ) ] = $ ( this )[ 0 ].value;
      }
    }

    if( tagName.toLowerCase () === 'select' ) {
      fields[ inputName ] = $ ( this ).find ( 'option:selected' ).attr ( 'value' );
    }

    if( tagName.toLowerCase () === 'textarea' ) {
      fields[  $ ( this ).data ( 'name' ) ] = $ ( this )[ 0 ].value;
    }
  } );

  return fields;
}

function sendRequest( requestType, requestParams ) {
  let sendButton = $ ( '#contactForm button[type="submit"], #carEnquiry button[type="submit"]' );

  $.ajax (
    {
      url        : enquiryAction,
      dataType   : 'json',
      method     : 'POST',
      data       : {
        action : 'atam_ajax_action',
        type   : requestType,
        data   : requestParams,
      },
      beforeSend : function () {
        if( requestType === 'car_enquiry' ) {
          sendButton.addClass('is_loading');
          sendButton.attr('disabled','disabled');
        }
        if( requestType === 'contact_enquiry' ){
          sendButton.addClass('is_loading');
          sendButton.attr('disabled','disabled');
        }
      },
      success    : function ( result ) {
        sendButton.removeClass('is_loading');
        sendButton.attr('disabled',null);

        if( requestType === 'car_enquiry') {
          if( result.response.status  ){
            $ ( '#carEnquiry' ).append('<div class="enquiry_response is_success">Pomyślnie złożono zapytanie</div>');
          }
          else {
            $ ( '#carEnquiry' ).append ( '<div class="enquiry_response is_error">Wystąpił błąd podczas wysyłania</div>' );
          }
        }

        if( requestType === 'contact_enquiry' ){

          alert('Wiadomość wysłana')
        }

      },
    } );
}

